import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomerShipping } from '../interfaces/customer-shipping';
import { ICountry } from '../interfaces/country';

export interface ICustomerShippingDynamicControlsParameters {
    formGroup?: string;
    countries?: ICountry[];
}

export class CustomerShippingDynamicControls {

    formGroup: string;
    countries?: ICountry[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customershipping?: ICustomerShipping, additionalParameters?: ICustomerShippingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerShipping';
        this.countries = additionalParameters && additionalParameters.countries || undefined;

        this.Form = {
            Active: new DynamicField({
                formGroup: this.formGroup,
                label: 'Active',
                name: 'Active',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.hasOwnProperty('Active') && this.customershipping.Active != null ? this.customershipping.Active : true,
            }),
            AddressIdentifierId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address Identifier',
                name: 'AddressIdentifierId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.AddressIdentifierId || null,
            }),
            CellPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cell Phone',
                name: 'CellPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customershipping && this.customershipping.hasOwnProperty('CellPhone') && this.customershipping.CellPhone != null ? this.customershipping.CellPhone.toString() : '',
            }),
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('CompanyName') && this.customershipping.CompanyName != null ? this.customershipping.CompanyName.toString() : '',
            }),
            CountryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country',
                name: 'CountryId',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.CountryId || null,
            }),
            CreatedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.CreatedBy || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.CustomerId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.DateModified || null,
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customershipping && this.customershipping.hasOwnProperty('FirstName') && this.customershipping.FirstName != null ? this.customershipping.FirstName.toString() : '',
            }),
            HomePhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Home Phone',
                name: 'HomePhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customershipping && this.customershipping.hasOwnProperty('HomePhone') && this.customershipping.HomePhone != null ? this.customershipping.HomePhone.toString() : '',
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.hasOwnProperty('IsPrimary') && this.customershipping.IsPrimary != null ? this.customershipping.IsPrimary : false,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customershipping && this.customershipping.hasOwnProperty('LastName') && this.customershipping.LastName != null ? this.customershipping.LastName.toString() : '',
            }),
            MiddleName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Name',
                name: 'MiddleName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customershipping && this.customershipping.hasOwnProperty('MiddleName') && this.customershipping.MiddleName != null ? this.customershipping.MiddleName.toString() : '',
            }),
            ModifiedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customershipping && this.customershipping.ModifiedBy || null,
            }),
            ShippingAddress1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Address1',
                name: 'ShippingAddress1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress1') && this.customershipping.ShippingAddress1 != null ? this.customershipping.ShippingAddress1.toString() : '',
            }),
            ShippingAddress2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Address2',
                name: 'ShippingAddress2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress2') && this.customershipping.ShippingAddress2 != null ? this.customershipping.ShippingAddress2.toString() : '',
            }),
            ShippingAddress3: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Address3',
                name: 'ShippingAddress3',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress3') && this.customershipping.ShippingAddress3 != null ? this.customershipping.ShippingAddress3.toString() : '',
            }),
            ShippingAddress4: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Address4',
                name: 'ShippingAddress4',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress4') && this.customershipping.ShippingAddress4 != null ? this.customershipping.ShippingAddress4.toString() : '',
            }),
            ShippingAddress5: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping Address5',
                name: 'ShippingAddress5',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress5') && this.customershipping.ShippingAddress5 != null ? this.customershipping.ShippingAddress5.toString() : '',
            }),
            ShippingCity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Shipping City',
                name: 'ShippingCity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingCity') && this.customershipping.ShippingCity != null ? this.customershipping.ShippingCity.toString() : '',
            }),
            WorkPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Work Phone',
                name: 'WorkPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customershipping && this.customershipping.hasOwnProperty('WorkPhone') && this.customershipping.WorkPhone != null ? this.customershipping.WorkPhone.toString() : '',
            }),
        };

        this.View = {
            Active: new DynamicLabel({
                label: 'Active',
                value: this.customershipping && this.customershipping.hasOwnProperty('Active') && this.customershipping.Active != null ? this.customershipping.Active : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AddressIdentifierId: new DynamicLabel({
                label: 'Address Identifier',
                value: this.customershipping && this.customershipping.AddressIdentifierId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CellPhone: new DynamicLabel({
                label: 'Cell Phone',
                value: this.customershipping && this.customershipping.hasOwnProperty('CellPhone') && this.customershipping.CellPhone != null ? this.customershipping.CellPhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompanyName: new DynamicLabel({
                label: 'Company Name',
                value: this.customershipping && this.customershipping.hasOwnProperty('CompanyName') && this.customershipping.CompanyName != null ? this.customershipping.CompanyName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CountryId: new DynamicLabel({
                label: 'Country',
                value: getMetaItemValue(this.countries as unknown as IMetaItem[], this.customershipping && this.customershipping.hasOwnProperty('CountryId') ? this.customershipping.CountryId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedBy: new DynamicLabel({
                label: 'Created By',
                value: this.customershipping && this.customershipping.CreatedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: this.customershipping && this.customershipping.CustomerId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.customershipping && this.customershipping.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.customershipping && this.customershipping.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.customershipping && this.customershipping.hasOwnProperty('FirstName') && this.customershipping.FirstName != null ? this.customershipping.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            HomePhone: new DynamicLabel({
                label: 'Home Phone',
                value: this.customershipping && this.customershipping.hasOwnProperty('HomePhone') && this.customershipping.HomePhone != null ? this.customershipping.HomePhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsPrimary: new DynamicLabel({
                label: 'Is Primary',
                value: this.customershipping && this.customershipping.hasOwnProperty('IsPrimary') && this.customershipping.IsPrimary != null ? this.customershipping.IsPrimary : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.customershipping && this.customershipping.hasOwnProperty('LastName') && this.customershipping.LastName != null ? this.customershipping.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MiddleName: new DynamicLabel({
                label: 'Middle Name',
                value: this.customershipping && this.customershipping.hasOwnProperty('MiddleName') && this.customershipping.MiddleName != null ? this.customershipping.MiddleName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedBy: new DynamicLabel({
                label: 'Modified By',
                value: this.customershipping && this.customershipping.ModifiedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ShippingAddress1: new DynamicLabel({
                label: 'Shipping Address1',
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress1') && this.customershipping.ShippingAddress1 != null ? this.customershipping.ShippingAddress1.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShippingAddress2: new DynamicLabel({
                label: 'Shipping Address2',
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress2') && this.customershipping.ShippingAddress2 != null ? this.customershipping.ShippingAddress2.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShippingAddress3: new DynamicLabel({
                label: 'Shipping Address3',
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress3') && this.customershipping.ShippingAddress3 != null ? this.customershipping.ShippingAddress3.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShippingAddress4: new DynamicLabel({
                label: 'Shipping Address4',
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress4') && this.customershipping.ShippingAddress4 != null ? this.customershipping.ShippingAddress4.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShippingAddress5: new DynamicLabel({
                label: 'Shipping Address5',
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingAddress5') && this.customershipping.ShippingAddress5 != null ? this.customershipping.ShippingAddress5.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ShippingCity: new DynamicLabel({
                label: 'Shipping City',
                value: this.customershipping && this.customershipping.hasOwnProperty('ShippingCity') && this.customershipping.ShippingCity != null ? this.customershipping.ShippingCity.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            WorkPhone: new DynamicLabel({
                label: 'Work Phone',
                value: this.customershipping && this.customershipping.hasOwnProperty('WorkPhone') && this.customershipping.WorkPhone != null ? this.customershipping.WorkPhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
