import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICatalogRequest } from '../interfaces/catalog-request';
import { ICustomer } from '../interfaces/customer';

export interface ICatalogRequestDynamicControlsParameters {
    formGroup?: string;
    customers?: ICustomer[];
}

export class CatalogRequestDynamicControls {

    formGroup: string;
    customers?: ICustomer[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private catalogrequest?: ICatalogRequest, additionalParameters?: ICatalogRequestDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CatalogRequest';
        this.customers = additionalParameters && additionalParameters.customers || undefined;

        this.Form = {
            Active: new DynamicField({
                formGroup: this.formGroup,
                label: 'Active',
                name: 'Active',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Active') && this.catalogrequest.Active != null ? this.catalogrequest.Active : true,
            }),
            Address1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address1',
                name: 'Address1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address1') && this.catalogrequest.Address1 != null ? this.catalogrequest.Address1.toString() : '',
            }),
            Address2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address2',
                name: 'Address2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address2') && this.catalogrequest.Address2 != null ? this.catalogrequest.Address2.toString() : '',
            }),
            Address3: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address3',
                name: 'Address3',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address3') && this.catalogrequest.Address3 != null ? this.catalogrequest.Address3.toString() : '',
            }),
            Address4: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address4',
                name: 'Address4',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address4') && this.catalogrequest.Address4 != null ? this.catalogrequest.Address4.toString() : '',
            }),
            Address5: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address5',
                name: 'Address5',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address5') && this.catalogrequest.Address5 != null ? this.catalogrequest.Address5.toString() : '',
            }),
            CatalogRequestId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Catalog Request',
                name: 'CatalogRequestId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.catalogrequest && this.catalogrequest.CatalogRequestId || null,
            }),
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('City') && this.catalogrequest.City != null ? this.catalogrequest.City.toString() : '',
            }),
            Comments: new DynamicField({
                formGroup: this.formGroup,
                label: 'Comments',
                name: 'Comments',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Comments') && this.catalogrequest.Comments != null ? this.catalogrequest.Comments.toString() : '',
            }),
            Country: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country',
                name: 'Country',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Country') && this.catalogrequest.Country != null ? this.catalogrequest.Country.toString() : '',
            }),
            CreatedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.CreatedBy || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: this.customers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.CustomerId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.DateModified || null,
            }),
            EmailAddress: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Address',
                name: 'EmailAddress',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('EmailAddress') && this.catalogrequest.EmailAddress != null ? this.catalogrequest.EmailAddress.toString() : '',
            }),
            FacilityName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Name',
                name: 'FacilityName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('FacilityName') && this.catalogrequest.FacilityName != null ? this.catalogrequest.FacilityName.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('FirstName') && this.catalogrequest.FirstName != null ? this.catalogrequest.FirstName.toString() : '',
            }),
            InBatch: new DynamicField({
                formGroup: this.formGroup,
                label: 'In Batch',
                name: 'InBatch',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('InBatch') && this.catalogrequest.InBatch != null ? this.catalogrequest.InBatch : false,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('LastName') && this.catalogrequest.LastName != null ? this.catalogrequest.LastName.toString() : '',
            }),
            MiddleName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Name',
                name: 'MiddleName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('MiddleName') && this.catalogrequest.MiddleName != null ? this.catalogrequest.MiddleName.toString() : '',
            }),
            ModifiedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.ModifiedBy || null,
            }),
            Quantity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Quantity',
                name: 'Quantity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.catalogrequest && this.catalogrequest.Quantity || null,
            }),
        };

        this.View = {
            Active: new DynamicLabel({
                label: 'Active',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Active') && this.catalogrequest.Active != null ? this.catalogrequest.Active : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Address1: new DynamicLabel({
                label: 'Address1',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address1') && this.catalogrequest.Address1 != null ? this.catalogrequest.Address1.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Address2: new DynamicLabel({
                label: 'Address2',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address2') && this.catalogrequest.Address2 != null ? this.catalogrequest.Address2.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Address3: new DynamicLabel({
                label: 'Address3',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address3') && this.catalogrequest.Address3 != null ? this.catalogrequest.Address3.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Address4: new DynamicLabel({
                label: 'Address4',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address4') && this.catalogrequest.Address4 != null ? this.catalogrequest.Address4.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Address5: new DynamicLabel({
                label: 'Address5',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Address5') && this.catalogrequest.Address5 != null ? this.catalogrequest.Address5.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CatalogRequestId: new DynamicLabel({
                label: 'Catalog Request',
                value: this.catalogrequest && this.catalogrequest.CatalogRequestId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            City: new DynamicLabel({
                label: 'City',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('City') && this.catalogrequest.City != null ? this.catalogrequest.City.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            Comments: new DynamicLabel({
                label: 'Comments',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Comments') && this.catalogrequest.Comments != null ? this.catalogrequest.Comments.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            Country: new DynamicLabel({
                label: 'Country',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('Country') && this.catalogrequest.Country != null ? this.catalogrequest.Country.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedBy: new DynamicLabel({
                label: 'Created By',
                value: this.catalogrequest && this.catalogrequest.CreatedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: getMetaItemValue(this.customers as unknown as IMetaItem[], this.catalogrequest && this.catalogrequest.hasOwnProperty('CustomerId') ? this.catalogrequest.CustomerId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.catalogrequest && this.catalogrequest.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.catalogrequest && this.catalogrequest.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            EmailAddress: new DynamicLabel({
                label: 'Email Address',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('EmailAddress') && this.catalogrequest.EmailAddress != null ? this.catalogrequest.EmailAddress.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FacilityName: new DynamicLabel({
                label: 'Facility Name',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('FacilityName') && this.catalogrequest.FacilityName != null ? this.catalogrequest.FacilityName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('FirstName') && this.catalogrequest.FirstName != null ? this.catalogrequest.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            InBatch: new DynamicLabel({
                label: 'In Batch',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('InBatch') && this.catalogrequest.InBatch != null ? this.catalogrequest.InBatch : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('LastName') && this.catalogrequest.LastName != null ? this.catalogrequest.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MiddleName: new DynamicLabel({
                label: 'Middle Name',
                value: this.catalogrequest && this.catalogrequest.hasOwnProperty('MiddleName') && this.catalogrequest.MiddleName != null ? this.catalogrequest.MiddleName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedBy: new DynamicLabel({
                label: 'Modified By',
                value: this.catalogrequest && this.catalogrequest.ModifiedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            Quantity: new DynamicLabel({
                label: 'Quantity',
                value: this.catalogrequest && this.catalogrequest.Quantity || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
        };

    }
}
