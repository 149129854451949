import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomerBilling } from '../interfaces/customer-billing';
import { ICountry } from '../interfaces/country';

export interface ICustomerBillingDynamicControlsParameters {
    formGroup?: string;
    countries?: ICountry[];
}

export class CustomerBillingDynamicControls {

    formGroup: string;
    countries?: ICountry[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customerbilling?: ICustomerBilling, additionalParameters?: ICustomerBillingDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'CustomerBilling';
        this.countries = additionalParameters && additionalParameters.countries || undefined;

        this.Form = {
            Active: new DynamicField({
                formGroup: this.formGroup,
                label: 'Active',
                name: 'Active',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('Active') && this.customerbilling.Active != null ? this.customerbilling.Active : true,
            }),
            BillingAddress1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address1',
                name: 'BillingAddress1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress1') && this.customerbilling.BillingAddress1 != null ? this.customerbilling.BillingAddress1.toString() : '',
            }),
            BillingAddress2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address2',
                name: 'BillingAddress2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress2') && this.customerbilling.BillingAddress2 != null ? this.customerbilling.BillingAddress2.toString() : '',
            }),
            BillingAddress3: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address3',
                name: 'BillingAddress3',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress3') && this.customerbilling.BillingAddress3 != null ? this.customerbilling.BillingAddress3.toString() : '',
            }),
            BillingAddress4: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address4',
                name: 'BillingAddress4',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress4') && this.customerbilling.BillingAddress4 != null ? this.customerbilling.BillingAddress4.toString() : '',
            }),
            BillingAddress5: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing Address5',
                name: 'BillingAddress5',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress5') && this.customerbilling.BillingAddress5 != null ? this.customerbilling.BillingAddress5.toString() : '',
            }),
            BillingCity: new DynamicField({
                formGroup: this.formGroup,
                label: 'Billing City',
                name: 'BillingCity',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingCity') && this.customerbilling.BillingCity != null ? this.customerbilling.BillingCity.toString() : '',
            }),
            CellPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cell Phone',
                name: 'CellPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('CellPhone') && this.customerbilling.CellPhone != null ? this.customerbilling.CellPhone.toString() : '',
            }),
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('CompanyName') && this.customerbilling.CompanyName != null ? this.customerbilling.CompanyName.toString() : '',
            }),
            CountryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Country',
                name: 'CountryId',
                options: this.countries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.CountryId || null,
            }),
            CreatedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.CreatedBy || null,
            }),
            CreditCardNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Credit Card Number',
                name: 'CreditCardNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(128) ],
                validators: { 'maxlength': 128 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('CreditCardNumber') && this.customerbilling.CreditCardNumber != null ? this.customerbilling.CreditCardNumber.toString() : '',
            }),
            CreditCardTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Credit Card Type',
                name: 'CreditCardTypeId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.CreditCardTypeId || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.CustomerId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.DateModified || null,
            }),
            ExpirationMonth: new DynamicField({
                formGroup: this.formGroup,
                label: 'Expiration Month',
                name: 'ExpirationMonth',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.ExpirationMonth || null,
            }),
            ExpirationYear: new DynamicField({
                formGroup: this.formGroup,
                label: 'Expiration Year',
                name: 'ExpirationYear',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.ExpirationYear || null,
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('FirstName') && this.customerbilling.FirstName != null ? this.customerbilling.FirstName.toString() : '',
            }),
            HomePhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Home Phone',
                name: 'HomePhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('HomePhone') && this.customerbilling.HomePhone != null ? this.customerbilling.HomePhone.toString() : '',
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('IsPrimary') && this.customerbilling.IsPrimary != null ? this.customerbilling.IsPrimary : false,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('LastName') && this.customerbilling.LastName != null ? this.customerbilling.LastName.toString() : '',
            }),
            MiddleName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Name',
                name: 'MiddleName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('MiddleName') && this.customerbilling.MiddleName != null ? this.customerbilling.MiddleName.toString() : '',
            }),
            ModifiedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customerbilling && this.customerbilling.ModifiedBy || null,
            }),
            NameOnCard: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name On Card',
                name: 'NameOnCard',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('NameOnCard') && this.customerbilling.NameOnCard != null ? this.customerbilling.NameOnCard.toString() : '',
            }),
            WorkPhone: new DynamicField({
                formGroup: this.formGroup,
                label: 'Work Phone',
                name: 'WorkPhone',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customerbilling && this.customerbilling.hasOwnProperty('WorkPhone') && this.customerbilling.WorkPhone != null ? this.customerbilling.WorkPhone.toString() : '',
            }),
        };

        this.View = {
            Active: new DynamicLabel({
                label: 'Active',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('Active') && this.customerbilling.Active != null ? this.customerbilling.Active : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddress1: new DynamicLabel({
                label: 'Billing Address1',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress1') && this.customerbilling.BillingAddress1 != null ? this.customerbilling.BillingAddress1.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddress2: new DynamicLabel({
                label: 'Billing Address2',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress2') && this.customerbilling.BillingAddress2 != null ? this.customerbilling.BillingAddress2.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddress3: new DynamicLabel({
                label: 'Billing Address3',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress3') && this.customerbilling.BillingAddress3 != null ? this.customerbilling.BillingAddress3.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddress4: new DynamicLabel({
                label: 'Billing Address4',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress4') && this.customerbilling.BillingAddress4 != null ? this.customerbilling.BillingAddress4.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingAddress5: new DynamicLabel({
                label: 'Billing Address5',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingAddress5') && this.customerbilling.BillingAddress5 != null ? this.customerbilling.BillingAddress5.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BillingCity: new DynamicLabel({
                label: 'Billing City',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('BillingCity') && this.customerbilling.BillingCity != null ? this.customerbilling.BillingCity.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CellPhone: new DynamicLabel({
                label: 'Cell Phone',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('CellPhone') && this.customerbilling.CellPhone != null ? this.customerbilling.CellPhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompanyName: new DynamicLabel({
                label: 'Company Name',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('CompanyName') && this.customerbilling.CompanyName != null ? this.customerbilling.CompanyName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CountryId: new DynamicLabel({
                label: 'Country',
                value: getMetaItemValue(this.countries as unknown as IMetaItem[], this.customerbilling && this.customerbilling.hasOwnProperty('CountryId') ? this.customerbilling.CountryId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedBy: new DynamicLabel({
                label: 'Created By',
                value: this.customerbilling && this.customerbilling.CreatedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CreditCardNumber: new DynamicLabel({
                label: 'Credit Card Number',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('CreditCardNumber') && this.customerbilling.CreditCardNumber != null ? this.customerbilling.CreditCardNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreditCardTypeId: new DynamicLabel({
                label: 'Credit Card Type',
                value: this.customerbilling && this.customerbilling.CreditCardTypeId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: this.customerbilling && this.customerbilling.CustomerId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.customerbilling && this.customerbilling.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.customerbilling && this.customerbilling.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            ExpirationMonth: new DynamicLabel({
                label: 'Expiration Month',
                value: this.customerbilling && this.customerbilling.ExpirationMonth || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ExpirationYear: new DynamicLabel({
                label: 'Expiration Year',
                value: this.customerbilling && this.customerbilling.ExpirationYear || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('FirstName') && this.customerbilling.FirstName != null ? this.customerbilling.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            HomePhone: new DynamicLabel({
                label: 'Home Phone',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('HomePhone') && this.customerbilling.HomePhone != null ? this.customerbilling.HomePhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsPrimary: new DynamicLabel({
                label: 'Is Primary',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('IsPrimary') && this.customerbilling.IsPrimary != null ? this.customerbilling.IsPrimary : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('LastName') && this.customerbilling.LastName != null ? this.customerbilling.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MiddleName: new DynamicLabel({
                label: 'Middle Name',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('MiddleName') && this.customerbilling.MiddleName != null ? this.customerbilling.MiddleName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedBy: new DynamicLabel({
                label: 'Modified By',
                value: this.customerbilling && this.customerbilling.ModifiedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            NameOnCard: new DynamicLabel({
                label: 'Name On Card',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('NameOnCard') && this.customerbilling.NameOnCard != null ? this.customerbilling.NameOnCard.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            WorkPhone: new DynamicLabel({
                label: 'Work Phone',
                value: this.customerbilling && this.customerbilling.hasOwnProperty('WorkPhone') && this.customerbilling.WorkPhone != null ? this.customerbilling.WorkPhone.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
