import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICustomer } from '@model/interfaces/customer';
import { IPageContent } from '@model/interfaces/page-content';
import { Observable } from 'rxjs';
import { CommonService } from '../../common/services/common.service';

@Injectable({
    providedIn: 'root',
})
export class PageContentService {
    constructor(
        public http: HttpClient,
        private _commonService: CommonService,
    ) {}

    getContent(pageContentId: number): Observable<IPageContent> {
        return this.http.get<IPageContent>(`/pageContent/${pageContentId}`);
    }

    getGreySubmitForGrading(enrollmentCodeId : number): Observable<IPageContent> {
        return this.http.get<IPageContent>(`/pageContent/grey-submit-for-grading/${enrollmentCodeId}`);
    }

    getActivateEnrollmentMergedContent(customer: ICustomer, content: IPageContent): string {
        let mergedContent = content.PageContent_;
        mergedContent = mergedContent.replace(/--Account FirstName--/gi, customer.FirstName);
        mergedContent = mergedContent.replace(/--Account MiddleName--/gi, customer.MiddleName || '');
        mergedContent = mergedContent.replace(/--Account LastName--/gi, customer.LastName);
        return mergedContent;
    }

    getHonorCodeMergedContent(customer: ICustomer, content: IPageContent): string {
        let mergedContent = content.PageContent_;
        mergedContent = mergedContent.replace(/--FirstName--/gi, customer.FirstName);
        mergedContent = mergedContent.replace(/--MiddleName--/gi, customer.MiddleName || '');
        mergedContent = mergedContent.replace(/--LastName--/gi, customer.LastName);
        return mergedContent;
    }

    getExpiredMessageMergedContent(courseName: string, expiration: Date, content: IPageContent): string {
        let mergedContent = content.PageContent_;
        mergedContent = mergedContent.replace(/--Name of Course--/gi, courseName);
        mergedContent = mergedContent.replace(/--Expiration date--/gi, this._commonService.formatDate(expiration));
        return mergedContent;
    }

    getArchivedMessageMergedContent(courseName: string, content: IPageContent): string {
        let mergedContent = content.PageContent_;
        mergedContent = mergedContent.replace(/--Name of Course--/gi, courseName);
        return mergedContent;
    }
}
