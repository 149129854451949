import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ICustomer } from '../interfaces/customer';
import { IAuthUser } from '../interfaces/auth-user';
import { ICountry } from '../interfaces/country';
import { IState } from '../interfaces/state';

export interface ICustomerDynamicControlsParameters {
    formGroup?: string;
    workCountries?: ICountry[];
    workStates?: IState[];
    authUsers?: IAuthUser[];
}

export class CustomerDynamicControls {

    formGroup: string;
    workCountries?: ICountry[];
    workStates?: IState[];
    authUsers?: IAuthUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private customer?: ICustomer, additionalParameters?: ICustomerDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Customer';
        this.workCountries = additionalParameters && additionalParameters.workCountries || undefined;
        this.workStates = additionalParameters && additionalParameters.workStates || undefined;
        this.authUsers = additionalParameters && additionalParameters.authUsers || undefined;

        this.Form = {
            AccessCustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Access Customer',
                name: 'AccessCustomerId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.AccessCustomerId || null,
            }),
            AccountCreationDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Account Creation Date',
                name: 'AccountCreationDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.AccountCreationDate || null,
            }),
            Active: new DynamicField({
                formGroup: this.formGroup,
                label: 'Active',
                name: 'Active',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('Active') && this.customer.Active != null ? this.customer.Active : true,
            }),
            AuthUserId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Auth User',
                name: 'AuthUserId',
                options: this.authUsers,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.AuthUserId || null,
            }),
            CdaEmailFlagManualUpdated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cda Email Flag Manual Updated',
                name: 'CdaEmailFlagManualUpdated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('CdaEmailFlagManualUpdated') && this.customer.CdaEmailFlagManualUpdated != null ? this.customer.CdaEmailFlagManualUpdated : false,
            }),
            CdaEmailSent: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cda Email Sent',
                name: 'CdaEmailSent',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.CdaEmailSent || null,
            }),
            CdaEmailSequence: new DynamicField({
                formGroup: this.formGroup,
                label: 'Cda Email Sequence',
                name: 'CdaEmailSequence',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.CdaEmailSequence || 0,
            }),
            CompanyName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Company Name',
                name: 'CompanyName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customer && this.customer.hasOwnProperty('CompanyName') && this.customer.CompanyName != null ? this.customer.CompanyName.toString() : '',
            }),
            CreatedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.CreatedBy || null,
            }),
            CustomerId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Customer',
                name: 'CustomerId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [ Validators.required ],
                validators: { 'required': true },
                value: this.customer && this.customer.CustomerId || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.DateModified || null,
            }),
            DisplayCertificates: new DynamicField({
                formGroup: this.formGroup,
                label: 'Display Certificates',
                name: 'DisplayCertificates',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('DisplayCertificates') && this.customer.DisplayCertificates != null ? this.customer.DisplayCertificates : true,
            }),
            EmailAddress: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email Address',
                name: 'EmailAddress',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(200) ],
                validators: { 'maxlength': 200 },
                value: this.customer && this.customer.hasOwnProperty('EmailAddress') && this.customer.EmailAddress != null ? this.customer.EmailAddress.toString() : '',
            }),
            EnrolledCode: new DynamicField({
                formGroup: this.formGroup,
                label: 'Enrolled Code',
                name: 'EnrolledCode',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('EnrolledCode') && this.customer.EnrolledCode != null ? this.customer.EnrolledCode.toString() : '',
            }),
            FacilityId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility',
                name: 'FacilityId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.FacilityId || null,
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('FirstName') && this.customer.FirstName != null ? this.customer.FirstName.toString() : '',
            }),
            HearAboutOther: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hear About Other',
                name: 'HearAboutOther',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.customer && this.customer.hasOwnProperty('HearAboutOther') && this.customer.HearAboutOther != null ? this.customer.HearAboutOther.toString() : '',
            }),
            HearAboutUsId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Hear About Us',
                name: 'HearAboutUsId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.HearAboutUsId || null,
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('LastName') && this.customer.LastName != null ? this.customer.LastName.toString() : '',
            }),
            MiddleName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Middle Name',
                name: 'MiddleName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('MiddleName') && this.customer.MiddleName != null ? this.customer.MiddleName.toString() : '',
            }),
            ModifiedBy: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedBy',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.ModifiedBy || null,
            }),
            OptToEmailMarketing: new DynamicField({
                formGroup: this.formGroup,
                label: 'Opt To Email Marketing',
                name: 'OptToEmailMarketing',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('OptToEmailMarketing') && this.customer.OptToEmailMarketing != null ? this.customer.OptToEmailMarketing : false,
            }),
            OptToEmailMarketingEndDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Opt To Email Marketing End Date',
                name: 'OptToEmailMarketingEndDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.OptToEmailMarketingEndDate || null,
            }),
            OptToEmailMarketingStartDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Opt To Email Marketing Start Date',
                name: 'OptToEmailMarketingStartDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.OptToEmailMarketingStartDate || null,
            }),
            Password: new DynamicField({
                formGroup: this.formGroup,
                label: 'Password',
                name: 'Password',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('Password') && this.customer.Password != null ? this.customer.Password.toString() : '',
            }),
            ReceiveCdaOutreachEmails: new DynamicField({
                formGroup: this.formGroup,
                label: 'Receive Cda Outreach Emails',
                name: 'ReceiveCdaOutreachEmails',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('ReceiveCdaOutreachEmails') && this.customer.ReceiveCdaOutreachEmails != null ? this.customer.ReceiveCdaOutreachEmails : false,
            }),
            RegistryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Registry',
                name: 'RegistryId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.customer && this.customer.hasOwnProperty('RegistryId') && this.customer.RegistryId != null ? this.customer.RegistryId.toString() : '',
            }),
            SendEmail: new DynamicField({
                formGroup: this.formGroup,
                label: 'Send Email',
                name: 'SendEmail',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.hasOwnProperty('SendEmail') && this.customer.SendEmail != null ? this.customer.SendEmail : true,
            }),
            StaffId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Staff',
                name: 'StaffId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(10) ],
                validators: { 'maxlength': 10 },
                value: this.customer && this.customer.hasOwnProperty('StaffId') && this.customer.StaffId != null ? this.customer.StaffId.toString() : '',
            }),
            UserName: new DynamicField({
                formGroup: this.formGroup,
                label: 'User Name',
                name: 'UserName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.customer && this.customer.hasOwnProperty('UserName') && this.customer.UserName != null ? this.customer.UserName.toString() : '',
            }),
            WorkCountryId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Work Country',
                name: 'WorkCountryId',
                options: this.workCountries,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.WorkCountryId || null,
            }),
            WorkStateId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Work State',
                name: 'WorkStateId',
                options: this.workStates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.customer && this.customer.WorkStateId || null,
            }),
        };

        this.View = {
            AccessCustomerId: new DynamicLabel({
                label: 'Access Customer',
                value: this.customer && this.customer.AccessCustomerId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            AccountCreationDate: new DynamicLabel({
                label: 'Account Creation Date',
                value: this.customer && this.customer.AccountCreationDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            Active: new DynamicLabel({
                label: 'Active',
                value: this.customer && this.customer.hasOwnProperty('Active') && this.customer.Active != null ? this.customer.Active : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AuthUserId: new DynamicLabel({
                label: 'Auth User',
                value: getMetaItemValue(this.authUsers as unknown as IMetaItem[], this.customer && this.customer.hasOwnProperty('AuthUserId') ? this.customer.AuthUserId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CdaEmailFlagManualUpdated: new DynamicLabel({
                label: 'Cda Email Flag Manual Updated',
                value: this.customer && this.customer.hasOwnProperty('CdaEmailFlagManualUpdated') && this.customer.CdaEmailFlagManualUpdated != null ? this.customer.CdaEmailFlagManualUpdated : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CdaEmailSent: new DynamicLabel({
                label: 'Cda Email Sent',
                value: this.customer && this.customer.CdaEmailSent || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            CdaEmailSequence: new DynamicLabel({
                label: 'Cda Email Sequence',
                value: this.customer && this.customer.CdaEmailSequence || 0,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CompanyName: new DynamicLabel({
                label: 'Company Name',
                value: this.customer && this.customer.hasOwnProperty('CompanyName') && this.customer.CompanyName != null ? this.customer.CompanyName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedBy: new DynamicLabel({
                label: 'Created By',
                value: this.customer && this.customer.CreatedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            CustomerId: new DynamicLabel({
                label: 'Customer',
                value: this.customer && this.customer.CustomerId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.customer && this.customer.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.customer && this.customer.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DisplayCertificates: new DynamicLabel({
                label: 'Display Certificates',
                value: this.customer && this.customer.hasOwnProperty('DisplayCertificates') && this.customer.DisplayCertificates != null ? this.customer.DisplayCertificates : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            EmailAddress: new DynamicLabel({
                label: 'Email Address',
                value: this.customer && this.customer.hasOwnProperty('EmailAddress') && this.customer.EmailAddress != null ? this.customer.EmailAddress.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            EnrolledCode: new DynamicLabel({
                label: 'Enrolled Code',
                value: this.customer && this.customer.hasOwnProperty('EnrolledCode') && this.customer.EnrolledCode != null ? this.customer.EnrolledCode.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FacilityId: new DynamicLabel({
                label: 'Facility',
                value: this.customer && this.customer.FacilityId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.customer && this.customer.hasOwnProperty('FirstName') && this.customer.FirstName != null ? this.customer.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            HearAboutOther: new DynamicLabel({
                label: 'Hear About Other',
                value: this.customer && this.customer.hasOwnProperty('HearAboutOther') && this.customer.HearAboutOther != null ? this.customer.HearAboutOther.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            HearAboutUsId: new DynamicLabel({
                label: 'Hear About Us',
                value: this.customer && this.customer.HearAboutUsId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.customer && this.customer.hasOwnProperty('LastName') && this.customer.LastName != null ? this.customer.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            MiddleName: new DynamicLabel({
                label: 'Middle Name',
                value: this.customer && this.customer.hasOwnProperty('MiddleName') && this.customer.MiddleName != null ? this.customer.MiddleName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedBy: new DynamicLabel({
                label: 'Modified By',
                value: this.customer && this.customer.ModifiedBy || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            OptToEmailMarketing: new DynamicLabel({
                label: 'Opt To Email Marketing',
                value: this.customer && this.customer.hasOwnProperty('OptToEmailMarketing') && this.customer.OptToEmailMarketing != null ? this.customer.OptToEmailMarketing : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            OptToEmailMarketingEndDate: new DynamicLabel({
                label: 'Opt To Email Marketing End Date',
                value: this.customer && this.customer.OptToEmailMarketingEndDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            OptToEmailMarketingStartDate: new DynamicLabel({
                label: 'Opt To Email Marketing Start Date',
                value: this.customer && this.customer.OptToEmailMarketingStartDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            Password: new DynamicLabel({
                label: 'Password',
                value: this.customer && this.customer.hasOwnProperty('Password') && this.customer.Password != null ? this.customer.Password.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Password,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReceiveCdaOutreachEmails: new DynamicLabel({
                label: 'Receive Cda Outreach Emails',
                value: this.customer && this.customer.hasOwnProperty('ReceiveCdaOutreachEmails') && this.customer.ReceiveCdaOutreachEmails != null ? this.customer.ReceiveCdaOutreachEmails : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            RegistryId: new DynamicLabel({
                label: 'Registry',
                value: this.customer && this.customer.hasOwnProperty('RegistryId') && this.customer.RegistryId != null ? this.customer.RegistryId.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            SendEmail: new DynamicLabel({
                label: 'Send Email',
                value: this.customer && this.customer.hasOwnProperty('SendEmail') && this.customer.SendEmail != null ? this.customer.SendEmail : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            StaffId: new DynamicLabel({
                label: 'Staff',
                value: this.customer && this.customer.hasOwnProperty('StaffId') && this.customer.StaffId != null ? this.customer.StaffId.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            UserName: new DynamicLabel({
                label: 'User Name',
                value: this.customer && this.customer.hasOwnProperty('UserName') && this.customer.UserName != null ? this.customer.UserName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            WorkCountryId: new DynamicLabel({
                label: 'Work Country',
                value: getMetaItemValue(this.workCountries as unknown as IMetaItem[], this.customer && this.customer.hasOwnProperty('WorkCountryId') ? this.customer.WorkCountryId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            WorkStateId: new DynamicLabel({
                label: 'Work State',
                value: getMetaItemValue(this.workStates as unknown as IMetaItem[], this.customer && this.customer.hasOwnProperty('WorkStateId') ? this.customer.WorkStateId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
