<br>
<div class="container miles-card padded">
    <h1>Request a Catalog</h1>
    <br>
    <form *ngIf="formControls" class="container" [formGroup]="catalogRequestForm" (ngSubmit)="formSubmitted()">
        <mt-dynamic-field [field]="formControls.Quantity" [form]="catalogRequestForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="formControls.FirstName" [form]="catalogRequestForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="formControls.MiddleName" [form]="catalogRequestForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="formControls.LastName" [form]="catalogRequestForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="formControls.FacilityName" [form]="catalogRequestForm"></mt-dynamic-field>
        <email-dynamic-field
            [field]="formControls.EmailAddress"
            [form]="catalogRequestForm"
            [fieldName]="'CatalogRequest.EmailAddress'"
        ></email-dynamic-field>

        <!-- Address -->
        <mt-dynamic-field
            [field]="formControls.Country"
            [form]="catalogRequestForm"
            (valueChanges)="newCountry($event)"
        ></mt-dynamic-field>
        <mt-dynamic-field [field]="formControls.Address1" [form]="catalogRequestForm"></mt-dynamic-field>
        <mt-dynamic-field [field]="formControls.Address2" [form]="catalogRequestForm"></mt-dynamic-field>
        <ng-container *ngIf="isCountryUS">
            <mt-dynamic-field [field]="formControls.City" [form]="catalogRequestForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="formControls.StateId" [form]="catalogRequestForm"></mt-dynamic-field>

            <zip-code-dynamic-field
                [field]="formControls.USZipCode"
                [form]="catalogRequestForm"
                [fieldName]="'CatalogRequest.USZipCode'"
                (invalidReason)="zipInvalid = $event"
            ></zip-code-dynamic-field>
        </ng-container>
        <ng-container *ngIf="!isCountryUS">
            <ng-container *ngIf="!isCountryCanada">
                <mt-dynamic-field [field]="formControls.Address3" [form]="catalogRequestForm"></mt-dynamic-field>
            </ng-container>
            <mt-dynamic-field [field]="formControls.City" [form]="catalogRequestForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="formControls.Address4" [form]="catalogRequestForm"></mt-dynamic-field>
            <mt-dynamic-field [field]="formControls.Address5" [form]="catalogRequestForm"></mt-dynamic-field>
        </ng-container>
        <span class="errortext">* </span>Indicates required field
        <div class="float-multiple-right">
            <button type="button" Class="btn btn-secondary" (click)="cancel()">Cancel</button>
            <button type="submit" mtDisableClickDuringHttpCalls style="margin-left: 10px;" Class="btn btn-primary btn-success">Save</button>
        </div>
    </form>
</div>
