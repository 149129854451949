// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=testing` then `environment.testing.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    adminSite: 'http://staging.admin.carecourses.com',
    adminSiteUploadImagePath: 'http://staging.admin.carecourses.com/UploadedImages/CourseDocuments/',
    apiVersion: '1',
    appName: 'Carecoursesbreck',
    appVersion: '1.0.0',
    assetsPath: 'assets/',
    authClientId: 1,
    authorizeNet: 'https://test.authorize.net/payment/payment',
    authSecretVariable: 'verysecret',
    baseApiUrl: 'https://cc.staging.milesapp.com',
    docPath: 'https://cc.staging.milesapp.com/docs/',
    enableProdMode: true,
    googleApiKey: '', // Empty this string to disable google login
    googleSiteVerification: 'oFKGmp2Ok4fK5m1Yvjmk4ZJLza5kgocA4z2yQLYSp1Qiui',
    gtmId: 'GTM-T5VBVSZ',
    imgPath: 'https://cc.staging.milesapp.com/docs/images/',
    logger: false,
    paypalClientId: 'AcD16BriwCvlhfTbQad2ulTluu6qSSoNExYL2zJGI_qA0eUR3GvSVddhCvYEv8Bnr4ch5SmXrDre4AR1',
    production: true,
    securityPolicyPage: 'http://cc.staging.milesapp.com/publicpages/Policy_PrivacySecurity',
    siteName: 'carecoursesbreck',
    submitHelpUrl: 'http://submit-help.testing.milesapp.com',

};
