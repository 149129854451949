export enum PageContentEnums {
    LoginPageBottom = 1,
    LoginPageCreateNew = 2,
    CourseLocker = 3,
    EnrollmentPage = 4,
    CourseCatalog = 5,
    CompletionofCourse = 6,
    ThankYouforYourPurchase = 7,
    LeftHandTestimonial = 10,
    GenericInformation = 11,
    Welcome = 12,
    RequestACatalog = 13,
    HonorCode = 14,
    PassMessage = 15,
    FailMessage = 16,
    RedoMessage = 17,
    SubmitForGrading = 18,
    CoursesByTopic = 19,
    SpeakWithTrainerMessage = 20,
    InactiveMessage = 21,
    GraySubmitForGrading = 22,
    ExpiredMessage = 23,
    ArchivedMessage = 25,
}

export enum ListItemTypeEnums {
    TopCountType = 1,
    TickerCategoryType = 2,
    HeaderFooterType = 3,
    LeadSource = 4,
    TypeOfFacility = 5,
    CourseTopics = 6,
    HearAboutUs = 7,
    CourseMedia = 8,
    CourseLanguage = 9,
    QuestionTypeID = 10,
    SignatureType = 11,
}

export enum CountriesEnums {
    Canada = 38,
    US = 223,
}

export enum WindowSizeEnums {
    Desktop = 1280,
    Tablet = 992,
    Mobile = 600,
}

export enum PaymentTypeEnums {
    Charge = 1,
    Cash = 2,
    Check = 3,
    Voucher = 4,
    PO = 5,
    PayPal = 6,
    None = 7,
}

export enum CardEntityListTypes {
    Orders = 1,
    OrderNotes = 2,
    Courses = 3,
}

export enum CourseTypeEnums {
    Online = 1,
    Offline = 2,
}

export enum QuizStatusEnums {
    Passed = 1,
    Redo = 2,
    InProgress = 3,
    NotStarted = 4,
    TBD = 5,
    Failed = 6,
    Incomplete = 7,
    SubmittedForGrading2ndTime = 8,
}

export enum QuizSubStatusEnums {
    Passed = 1,
    GoodJob = 2,
    Excellent = 3,
    SubmittedForGrading = 4,
    SpeakWithATrainer = 5,
    Inactive = 6,
    StartOver = 7,
}

export const QuizSubStatusText = {
    1: 'Passed',
    2: 'Good Job',
    3: 'Excellent',
    4: 'Submitted For Grading',
    5: 'Speak With A Trainer',
    6: 'Inactive',
    7: 'Start Over',
};

export enum CourseSortBy {
    Title = 1,
    Hours = 2,
    Topic = 3,
}

export enum OrderStatusEnums {
    Ordered = 1,
    Pending = 2,
    Declined = 3,
}

export enum ShippingTypes {
    Standard = 1,
    Priority = 2,
    Express = 3,
    International = 4,
}

export enum MediaType {
    CourseBookAndTextBookId1 = 11,
    CourseBookAndTextBookId2 = 44,
    LegacyCourseBook = 31,
    CourseBookId1 = 63,
    CourseBookId2 = 106,
}

export const GradingWizardSteps = {
    1: 'Course Evaluation',
    2: 'Honor Code',
    3: 'Work Location',
    4: 'Confirmation',
    5: 'View Results',
};

export const GradingWizardStepsSkipEval = {
    2: 'Honor Code',
    3: 'Work Location',
    4: 'Confirmation',
    5: 'View Results',
};

export enum GradingWizardStepsEnum {
    CourseEvaluation = 1,
    HonorCode = 2,
    WorkLocation = 3,
    Confirmation = 4,
    ViewResults = 5,
}

export enum EvaluationQuestionType {
    Options = 1,
    FreeText = 2,
    Sequencing = 3
}

export enum LessonQuizMessageEnums {
    SubmitForGradingMessage = 1,
    FailedMessage = 2,
    ExpiredMessage = 3,
}

export enum ItemNumberEnums {
    FasterCertificateDelivery = 'EXPC-33E',
}

export enum QuizContentPageType {
    SelfCheck = 1,
    LessonQuiz = 2,
    CourseContent = 3,
    InteractiveSection = 4,
}

/** These URL fragments render components that are part of the course */
export enum QuizContentUrlFragments {
    SelfCheck = 'self-check',
    LessonQuiz = 'lesson-quiz',
    DiscussionArea = 'discussion-area',
    InteractiveSection = 'interactive',
}

/** These URL fragments render components that are NOT part of the course */
export enum QuizOutOfCourseUrlFragments {
    ContactMyTrainer = 'contact-my-trainer',
    Documents = 'documents',
    GradingWizard = 'grading-wizard',
    QuizResults = 'results',
    CannotSubmit = 'cannot-submit',
    Archived = 'archived',
}

export enum ItemTypeEnum {
    Course = 1,
    Service = 2,
    Other = 3,
    Bundle = 4,
}

export enum MetaItemType {
    Format = 1,
    Item = 2,
    Bundle = 3,
}

export enum AuthorizeNetAccountTypes {
    ECheck = 'eCheck',
}

export enum CourseLogPageType {
    DocumentPage = 1,
    ContactMyTrainer = 2,
    CannotSubmit = 3,
    GradingWizard = 4
}

export enum MultiSelectQuizState {
    NotGraded = 1,
    Passed = 2,
    Failed = 3
}

export enum SequenceQuizState {
    NotGraded = 1,
    Passed = 2,
    Failed = 3
}

export enum ImageAltAndTitleText {
    DefaultImageTitle = '',
    // DefaultImageTitle = 'Default Image',
    DefaultImageAlt = 'Default Image, white square with grey silhouette',
    ManagedContentImageFor = 'Image for',
    CreditCardTitle = '',
    // CreditCardTitle = 'Accepted Credit Cards',
    CreditCardAlt = 'Four Credit Cards. Visa, Mastercard, American Express, and Discover.',
    PaypalTitle = '',
    // PaypalTitle = 'Paypal Logo',
    PaypalAlt = 'Paypal Logo. Dark Blue P and Light Blue P overlapping with the word Paypal underneath.',
    CDASealTitle = '',
    // CDASealTitle = 'Receive CDA Outreach Emails',
    CDASealAlt = 'Medal with Border. Text saying CDA Gold Standard Comprehensive.',
    MyInfoTitle = '',
    // MyInfoTitle = 'My Information',
    MyInfoAlt = 'Black Circle with a silhouette of person inside.',
    MyCoursesTitle = '',
    // MyCoursesTitle = 'My Courses',
    MyCoursesAlt = 'Projector Screen',
    MyOrdersTitle = '',
    // MyOrdersTitle = 'My Orders',
    MyOrdersAlt = 'Credit Card being inserted into Credit Card Reader',
    IACETLogoTitle = '',
    // IACETLogoTitle = 'IACET Logo',
    IACETLogoAlt = 'Logo with text: Accredited I A C E T Provider.',
    TNWRALogoTitle = '',
    // TNWRALogoTitle = 'TNWRA Logo',
    TNWRALogoAlt = 'Logo with text: The National Workforce Registry Alliance. Recognized Training Organization.',
    CareCourseLogoTitle = '',
    // CareCourseLogoTitle = 'Care Courses Logo',
    CareCourseLogoAlt = 'Circle with an image of four children in the center. Surrounding text says: Caring for Caregivers for the future is in their hands',
    SmileFaceTitle = '',
    // SmileFaceTitle = 'Smiley Face',
    SmileFaceAlt = 'Yellow cartoon smiling face',
    SmileFaceNavResultsAlt = 'Yellow cartoon smiling face. Navigates to Lesson Quiz.',
    RedoIconTitle = '',
    // RedoIconTitle = 'Redo',
    RedoIconAlt = 'Red square with text in center saying Redo',
    RedoIconNavResultsAlt = 'Red square with text in center saying Redo. Navigates to Lesson Quiz.',
    CompleteDiskTitle = '',
    // CompleteDiskTitle = 'Completed',
    CompleteDiskAlt = 'Blue floppy disk. This quiz is complete.',
    IncompleteDiskTitle = '',
    // IncompleteDiskTitle = 'Incomplete',
    IncompleteDiskRedoIconAlt = 'Yellow floppy disk. This quiz is incomplete.',
}
